import React from 'react';
import { Link } from 'gatsby';
import queryString from 'query-string'
import * as Paths from '../../constants/Paths'
import LayoutA from "../../layouts/LayoutA";
import {Spinner} from '@blueprintjs/core'
import {authWithGoogleCode} from '../../api/SessionApi';

class OauthCallback extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      error: "wow"
    }

  }

  componentDidMount() {
    var query = queryString.parse(window.location.search);

    authWithGoogleCode(query.code).then((result) => {
      window.location = "/dashboard/"
    });

  }

  render() {

    const { error } = this.state;

    return (
      <LayoutA>
        <div className="LoginTemplate">
          <Spinner />
        </div>
      </LayoutA>
    );
  }
}

export default OauthCallback;